import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";

import dazon_img_1 from '../../../images/DazonDixon.jpg';
import dazon_img_1_mobile from '../../../images/DazonDixon_mobile.jpg';

//const fullName = "change full name";
const signatureImage = <img src="/Dazon-Signature.png" alt="Dazon Dixon Diallo Signature" className="signatureImage" />;

const copyList = [

    {
        type: "image",
        content: {
            src: dazon_img_1,
            mobileSrc: dazon_img_1_mobile,
            alt: "Dázon Dixon Diallo",
            caption: "Dázon Dixon Diallo"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Dázon Dixon Diallo, founder of SisterLove, Inc., the first women-focused HIV/AIDS and reproductive justice organization in the southeastern US"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Icon"
    // },

    {
        type: 'text',
        content: `A Georgia girl through and through, Dázon Dixon Diallo, founder of SisterLove, Inc.*—the first 
        women’s HIV/AIDS and reproductive justice organization in the Southeastern United 
        States—can trace her grassroots organizing back to a standoff between her mom and her 8th 
        grade biology class. “My mother was our teacher, and we’d gotten to that part where you 
        dissect the frog. I just couldn’t,” she says. She called every one of her classmates and 
        told them not to bring in their frogs the next day. “That was my first 
        protest. I realized what the power of organizing people could do.”`
    },
    {
        type: "blockquote",
        content: "“I realized what the power of organizing people could do.”",
        author: signatureImage
        //author: fullName
    },

    {
        type: 'text',
        content: "At the onset of the AIDS epidemic in the ‘80s, Diallo was working at a reproductive health clinic when the local AIDS organization “organized by gay white men for gay white men” reached out when women started calling, asking, ‘What does this mean for me?’” At the time, the organization didn’t have answers, so Diallo “started volunteering to learn and get engaged.” Then she started to do the math long before many others did. “I was like, ‘Okay, straight women have sex with men, gay men have sex with men; we have something in common that we need to talk about.’” She first created an event called the Safe Sex Party that got women talking about their sexual health at a time when that was taboo. From there, “we created events that were positive, centered on women’s eroticism, our sexuality, but were also very informative, so when they left the workshop, they were ready to go do something, but do it good and do it safe.”"
    },
    {
        type: "text",
        content: `It became the event that sparked her revolutionary organization, SisterLove, Inc., now in 
        its 34th year. “We’re talking HIV, trauma, STIs, reproductive health, and 
        your rights,” says Diallo. “We transformed health education for them.” These days, SisterLove has 
        multiple offerings, including a mobile clinic and outreach initiatives 
        like Healthy Love Experience. “I was 24 when I started SisterLove,” says the 
        activist, compelled to help women survive HIV/AIDS long before there was any 
        research or resources. In the time since, she’s become adept at helping women 
        living with HIV get the educational resources and care they need to live fulfilling lives. Her 
        powerful legacy includes establishing the first-ever support group in Atlanta 
        for women living with HIV, along with numerous other programs and initiatives centered on the intersectional 
        experiences of Black women and women of color, giving them agency in the greater HIV community.`
    },
    {
        type: "text",
        content: "“I can’t get tired,” she says. So committed to the work, her favorite way to decompress is behind the mic of her long-running radio show, where she continues to spread the information needed to help improve women’s lives and motivate others to do the same. She and SisterLove serve as the blueprint for many others advocating for women in HIV/AIDS, and she’s not done yet. Says Diallo, “No matter my role, I can’t give up this fight.”"
    },
    {
      type: "disclaimer",
    },

    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">DÁZON DIXON DIALLO"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">SisterLove, Inc."
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a class=\"championsofchangelandinglink\" href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    },

]

class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Dázon Dixon Diallo, The Icon"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      


      </div>
    );
  }
}

export default Content;
